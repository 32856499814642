<script setup lang="ts">
const props = withDefaults(defineProps<{
  navbarDark?: boolean
  pagePadding?: boolean
}>(), {
  navbarDark: true,
})

const { y } = useWindowScroll()
const scrolled = computed(() => y.value > 0)
watch(scrolled, (hasScrolled) => {
  document.querySelector('body')?.classList.toggle('scrolled', hasScrolled)
})

const route = useRoute()
const routeName = computed(() => `${route.name as string}`.split('___')[0])

const me = useProfile()
const sidebar = computed(() => !!me.value && routeName.value.startsWith('pro'))

const open = useState('global-search-open', () => false)
</script>

<template>
  <div class="layout">
    <Html>
      <Body class="text-gray-700 font-body bg-gray-50 next-layout antialiased">
        <div id="ui-page-layout" :class="{ scrolled }">
          <UiNavbar
            id="navbar"
            :is-dark="navbarDark"
            class="fixed inset-x-0"
            :class="{
              'shadow-card': !$slots.topbar,
            }"
          />
          <UiTopbar
            v-if="$slots.topbar"
            id="topbar"
            class="fixed inset-x-0 top-navbar"
            :class="{
              'md:pl-sidebar': sidebar,
            }"
          >
            <slot name="topbar" />
          </UiTopbar>
          <UiSidebar
            v-if="sidebar"
            id="sidebar"
            class="fixed bottom-0 left-0 hidden z-sidebar top-navbar w-sidebar md:block"
          />

          <div
            class="pb-36 layout-container"
            :class="{
              'topbar': $slots.topbar,
              'md:pl-sidebar': sidebar,
            }"
          >
            <div v-if="$slots.breadcrumbs" class="px-4 pt-4 pb-6 md:px-6 lg:px-20 md:pt-8 md:pb-12">
              <slot name="breadcrumbs" />
            </div>

            <div
              :class="{
                'px-4 md:px-6 lg:px-20': pagePadding,
              }"
            >
              <slot />
            </div>
          </div>

          <UiFooter class="relative z-footer" />
          <ClientOnly>
            <LazyBlockConditionsModal />
            <LazyBlockSearch v-if="open" />
          </ClientOnly>
        </div>
      </Body>
    </Html>
  </div>
</template>

<style lang="scss">
body.next-layout {
  --navbar: theme('spacing.navbar-sm');
  --navbar-absolute: theme('spacing.navbar-sm');
  --topbar: theme('spacing.topbar-sm');
  --topbar-absolute: theme('spacing.topbar-sm');
  --menu-btn-w: theme('spacing.menu-btn-w-sm');

  &.scrolled {
    --navbar: theme('spacing.navbar-sm-scrolled');
    --topbar: theme('spacing.topbar-sm-scrolled');
  }

  @screen lg {
    --navbar: theme('spacing.navbar-md');
    --navbar-absolute: theme('spacing.navbar-md');
    --topbar: theme('spacing.topbar-md');
    --topbar-absolute: theme('spacing.topbar-md');
    --menu-btn-w: theme('spacing.menu-btn-w-md');

    &.scrolled {
      --navbar: theme('spacing.navbar-md-scrolled');
      --topbar: theme('spacing.topbar-md-scrolled');
    }
  }
}
</style>

<style scoped lang="scss">
#ui-page-layout {
  #navbar {
    @apply h-navbar z-navbar;

    max-height: theme('spacing.navbar');
  }

  #topbar {
    @apply h-topbar z-topbar;

    max-height: theme('spacing.topbar');
  }

  #navbar,
  #topbar,
  #sidebar {
    transition: all 0.2s linear;
  }

  .layout-container {
    @apply pt-navbar-absolute min-h-screen;

    &.topbar {
      padding-top: calc(#{theme('spacing.navbar-absolute')} + #{theme('spacing.topbar-absolute')});
    }
  }
}
</style>
